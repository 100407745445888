import React from 'react';

const dataStored = {
    studios: [
      {
        id: 1,
        title: "HOT IRON® Cross️ by IFHIAS",
        para1: "<p>Everyone is talking about HIIT and cross-training – for good reason! Maximum body fat reduction, firming and daily energy are achieved in !is course with maximum efficiency, which then also leads to self-belief and self-confidence in everyday life.</p><p class='my-2'>HOT IRON® CROSS works wi! relatively few repetitions and effective training combinations. This ensures firmer and metabolically active tissue and thus an increased basal metabolic rate.</p><p>The perceptible and visible results include significant body fat reduction, a toned physique, a strong back and a significant increase in everyday quality of life.</p><p>HOT IRON® CROSS – the strenght-interval workout with barbell and bodyweight exercises.</p>",
        video_url: "https://youtu.be/IRcvWdaQWZY"
      },
      {
        id: 2,
        title: "Yonga®️ by IFHIAS",
        para1: "<p>Our everyday lives are o!en hectic and stressful. We want a balance: Having time to think, enjoying being active and recharging our batteries. At the same time, we experience the seasons along with nature. They determine our energy, create different challenges and open different opportunities for us.</p><p class='my-4'>These seasonal energies are channeled into modern movement sequences. For example, influences from qi gong, yoga and tai chi ensure a modern, holistic workout.</p><p>Feel the synergy of strengthening, flexibility and balance. Breathing and perception also play an important role in this. Slow down your daily routine with YONGA – your group training without additional equipment.</p>",
        video_url: "https://youtu.be/xdS9Vkrv8vQ"
      },
      {
        id: 3,
        title: "Chakaboom Fitness®",
        para1: "<p>CHAKABOOM®: The optimal dance fitness experience is a high-energy group fitness program influenced by the rhythm and culture of the countries of the world and designed for people at all fitness levels.<p><p class='my-4'>CHAKABOOM® incorporates various styles of dance and music into an explosive group fitness experience, providing a full body workout. Their class will motivate, challenge and inspire participants leaving then feeling energized and accomplished and wanting to be their best in overall health and fitness.</p><p class='my-4'>The word CHAKABOOM means ‘Energy from the heart’. CHAKABOOM Fitness aims to capture this energy and infuse it into every class- for everyone.</p><p>CHAKABOOM Fitness was created from a depth of passion and commitment to health, fotness and well-being by Leo Lins and Roberson Magalhaes. Seeking to bring something different and fresh to the fitness industry, these two world-renowned athletes created a program to shape the body and nourish the soul. The CHAKABOOM Fitness program is designed not only to help you get shape, but to encourage and motivate you to be the best you can be, no matter your fitness level, combining dynamic music, dance moves, strength training and cardio. You’ll burn calories, gain power and endurance.</p>"
      },{
        id: 4,
        title: "Masala Bhangra®",
        para1: "<p>Named as one of the top 5 workouts in the US, The Masala Bhangra. Masala Bhangra is derived from two Indian words ‘Masala’ which means spicy in Hindi and ‘Bhangra’ a traditional folk dance from the northern state of Punjab in India. The Masala Bhangra workout is a complete cardiovascular workout that blends traditional Bhangra dance steps with the exhilaration of Bollywood moves. Masala Bhangra will channel your masculine side with bhangra & your feminine grace with Bollywood. Move to the beat of the dhol and unleash your inner being to a higher level.</p><p class='mt-2'>A certified fitness program backed up by the fitness boards and devised by Sarina Jain, the workout provides overall toning, body conditioning and can build endurance, stamina and balance, in addition to burning up 500 calories per class (depending on individual).</p>"
      },{
        id: 5,
        title: "Zumba®️ ZIN™",
        para1: "<p>Grooving to the beats of salsa, flamenco, and merengue music feels more like a dance party than a workout, which is exactly what makes Zumba® so popular. The Latin-inspired dance workout is one of the most popular group exercise classes in the world.</p><p class='my-2'>The high-energy classes are set to upbeat music and feature choreographed dance numbers that you might see in a nightclub. You don’t need to be a great dancer to feel welcome in a Zumba® class. With the tag line, “Ditch the Workout, Join the Party,” the classes emphasize moving to the music and having a good time, no rhythm required.</p><p>Working up a sweat in the 60-minute classes burns an average of 369 calories -- more than cardio kickboxing or step aerobics. You’ll get a great cardio workout that melts fat, strengthens your core, and improves flexibility.</p>"
      }
    ],
    instructors:  [{
      "id": 1,
      "title": "ZIN™ Johnson Chye",
      "para1": "One of Malaysia’s very own fitness icon, Johnson Chye is known by many for his playful charisma. An accomplished Zumba® Fitness instructor, Johnson has a passion for improving people’s lives through fitness by making small healthier changes.",
      "para2": "With many years of experience in the fitness industry, his classes are very popular because of his unique brand of dance and super funky dance moves. He instructs and performs at many events across Asia.",
      "img_url": "/assets/images/coach-johnson-chye-big.png"
    }, {
      "id": 2,
      "title": "Zumba® Jammer - William Villaseñor, ",
      "para1": "HE IS COMING !! THE MOST ANTICIPATED FITNESS CONCERT 2022!! William Villaseñor is an internationally recognised Zumba® Jammer, singer and choreographer who has done so much more than people realise. He has been known as the “WildShaker” for his unique dancing style and people around the globe are teaching classes with his choreographies, he is charismatic, talented, witty, fun and most of all he can dance!!!!",
      "para2": "So if you are ready to smile, feel his energy and shake it down to the ground! Unite with The Shakers of Malaysia and let's have a great time with William \"The WildShaker\"!!",
      "img_url": "/assets/images/coach-william-villaseñor-big.png",
      "video_url": "https://youtu.be/sHxCiZcAmag"
    }, {
      "id": 3,
      "title": "Roberson Magalhaes (co-founder of Chakaboom Fitness®)",
      "para1": "Born in Barbacena, Brazil, Roberson has been in the fitness industry for more than 20yrs. He holds NINE World Aerobic Gymnastic titles and is an international presenter and master trainer. He has presented at Fitness Conventions in over 20 countries and is unsurpassed as one of the best and most successful athletic coaches and choreographer in competitive Aerobic Gymnastics. Before creating The Chakaboom Fitness® program, Roberson served as Group Fitness Director for a chain of national gyms where he managed operations and developed new fitness programs. Roberson has been instrumental in producing as well as been featured in videos for Hip-Hop, Kick-boxing, Kangoo Jumps and other dance and exercise styles. Roberson’s experience, creativity, training and enthusiasm will motivate and encourage you to be your best.",
      "img_url": "/assets/images/coach-robertson-magalhae-big.png"
    }, {
      "id": 4,
      "title": "Yonga® / HOT IRON® Cross️ by IFHIAS Christian Spoerri",
      "para1": "Christian comes from Zuerich/Switzerland and deals with various forms of movement, body awareness and body work out of vocation. Since his early youth, he has been enthusiastic about a wide variety of sports through their joy of playing and movement. Later he also learned to appreciate the positive effects of more focused physical and mental disciplines. He can now look back on more than 25 years of teaching experience in both classic forms of training and alternative, holistic approaches. He finds the connection and balance of supposedly opposing disciplines in the sense of the yin-yang principle exciting.",
      "para2": "Christian is a certified Shiatsu therapist, Qi-Gong and Tai Chi teacher, personal trainer and educator for the IFHIA",
      "img_url": "/assets/images/coach-christian-spoerri-big.png"
    }, {
      "id": 5,
      "title": "Masala Bhangra®️ Shah Barian & Aliez Jazam",
      "para1": "<p>Shaharul Bariah Hussain - active in the fitness industry for 30 years. I'm an ACE group fitness instructor and also Accredated Group Fitness Instructor of Kementerian Belia dan Sukan Malaysia.Shah took up Masala Bhangra license in 2014 abroad and was appointed as Master Trainer in 2016- Handling trainings / masterclasses and workshops beside classes.</p><p class='mt-2'>Fitness has always be her passion and priority in life. Her mission and vision is to get everyone living in an active lifestyle regardless of age and gender.</p><p class='my-2'>Aliez Jazam - active in the fitness industry for 20 years. She was appointed as Master Trainer of Masala Bhangra Workout in 2016.</p><p>On another note, fitness has always been her passion ever since she was young. Her mission is to get everyone active in all forms and genre workout while spreading the love and positivity along the way.<p>",
      "img_url": "/assets/images/coach-shah-aliez-big.png"
    }],
  };
  
  export const MyContext = React.createContext(dataStored);