import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";

import './MobileHeader.css';




export default function MobileHeader() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [stickyClass, setStickyClass] = useState("relative");
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 200
        ? setStickyClass(`fixed top-0 left-0 z-50 stickyHeader`)
        : setStickyClass("relative");
    }
  };  
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const registerNow = (e) => {
    window.open("https://www.ticket2u.com.my/event/28713", "_blank");
  };
  return (
    <div id="mobile-navi" className={`mobile-navi ${stickyClass}`}>
      <nav className="navBar">
        <div className="flex flex-wrap">
          <div className="w-1/3">
            {" "}
            <button onClick={handleToggle} className="menu">
              {navbarOpen ? (
                <MdClose
                  style={{ color: "#fff", width: "40px", height: "40px" }}
                />
              ) : (
                <FiMenu
                  style={{ color: "#fff", width: "40px", height: "40px" }}
                />
              )}
            </button>
          </div>
          <div className="w-1/3 text-center">
            <img
              src="/assets/images/logo.png"
              alt="HOmepage"
              className="logo"
            />
          </div>
          <div className="w-1/3">
            {" "}
            <button
              className="btn green-btn relative"
              onClick={() => {
                registerNow();
              }}
            >
              REGISTER NOW
            </button>
          </div>
        </div>
        <div className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
          <img src="/assets/images/logo.png" alt="HOmepage" className="logo" />
          <ul>
            <li>
              <a href="#about" onClick={handleToggle} >ABOUT FITNESS FEST</a>
            </li>
            <li>
              <a href="#programmes" onClick={handleToggle} >PROGRAMMES</a>
            </li>
            <li>
              <a href="#presenters" onClick={handleToggle} >PRESENTERS</a>
            </li>
            <li>
              <a href="#partnership" onClick={handleToggle} >PARTNERSHIP</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
