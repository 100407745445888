import "./Partnership.css";

export default function Partnership() {
  return (
    <div id="partnership" className="py-11 text-center">
      <h3>PARTNERSHIP</h3>
      <h2 className="md:mt-4 mb-8">Our Partners</h2>
      <div className="w-2/3 m-auto">
        <ul className="md:flex items-center">
          <li className="md:w-3/12">
            <img
              src="/assets/images/logo-ifhias.png"
              alt="partnership"
              className="logo-fat"
            />
          </li>
          <li className="md:w-3/12 md:my-0 my-4 md:mx-4">
            <img
              src="/assets/images/logo-leapnow.png"
              alt="partnership"
              className="logo-fat"
            />
          </li>
          <li className="md:w-3/12">
            <img
              src="/assets/images/logo-starxpo.png"
              alt="partnership"
              className="logo-fat"
            />
          </li>
          <li className="md:w-3/12 md:my-0 my-4 md:mx-4">
            <img
              src="/assets/images/logo-capri.png"
              alt="partnership"
              className="logo-fat"
            />
          </li>
        </ul>
        <ul className="md:flex mt-4 md:mt-8 items-center">
          <li className="md:w-3/12">
            <img
              src="/assets/images/logo-chakaboom.png"
              alt="partnership"
              className="logo-fat"
            />
          </li>
          <li className="md:w-3/12 m-auto">
            <img
              src="/assets/images/logo-gorrila.png"
              alt="partnership"
              className="logo-slim md:m-0 m-auto w-1/2 md:h-auto h-1/2"
            />
          </li>
          <li className="md:w-3/12">
            <img
              src="/assets/images/logo-peak.png"
              alt="partnership"
              className="logo-fat"
            />
          </li>
          <li className="md:w-3/12 md:my-0 my-4 md:mx-4">
            <img
              src="/assets/images/logo-howei.png"
              alt="partnership"
              className="logo-fat"
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
