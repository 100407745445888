import { useState, useContext } from "react";
import Modal from "../components/Modal";
import Backdrop from "../components/Backdrop";

import "./Programmes.css";
import { MyContext } from "../MyContext";

export default function Programmes() {
  const contextData = useContext(MyContext);
  let [prompData, coachProp] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  function closeModalHandler() {
    setModalIsOpen(false);
  }

  function refreshDataHandler(obj) {
    setModalIsOpen(false);
  }
  const openPrompt = (e) => {
    var clickData = contextData.instructors.filter(function (item) {
      return item.id === e;
    });
    const details = {
      type: "instructors",
      value: clickData[0],
    };
    coachProp(details);
    setModalIsOpen(true);
  };
  const openPromptMore = (e) => {
    var _clickData = contextData.studios.filter(function (item) {
      return item.id === e;
    });

    const _details = {
      type: "studios",
      value: _clickData[0],
    };

    coachProp(_details);
    setModalIsOpen(true);
  };
  return (
    <div
      id="programmes"
      className={`relative blue-bg pady-48 ${modalIsOpen ? "onModal" : null}`}
    >
      <div className="blue-bg container  m-auto">
        <p className="text-center text-lg">PROGRAMS</p>
        <h2 className="text-center">Program of LEAP NOW Fitness Fest 2022</h2>
        {/* <p className="text-center my-8 details">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p> */}
        <div id="schedule" className="md:flex mt-8 md:mx-auto">
          <img
            src="/assets/images/event-date.png"
            alt="the day"
            className="m-auto mb-6 m md:m-0 hidden md:block"
            id="event-date"
          />
          <img
            src="/assets/images/event-date-2.png"
            alt="the day"
            className="m-auto mb-6 md:m-0 block md:hidden"
            id="event-date"
          />
          <ul className="md:ml-16">
            <li className="block md:flex">
              <p className="event-time md:mr-8 mr-0  md:text-left text-center">
                8:00{" "}
              </p>
              <p className="event-details">Registration opens</p>
            </li>
            <li className="block md:flex my-4">
              <p className="event-time md:mr-8 mr-0 md:text-left text-center">
                09:00 - 10:30
              </p>
              <p className="event-details">
                <strong
                  onClick={() => {
                    openPromptMore(1);
                  }}
                >
                  HOT IRON® Cross️
                </strong>{" "}
                by IFHIAS{" "}
                <strong
                  onClick={() => {
                    openPrompt(4);
                  }}
                >
                  Christian Spoerri
                </strong>
              </p>
            </li>
            <li className="block md:flex my-4">
              <p className="event-time md:mr-8 mr-0 md:text-left text-center">
                11:00 - 12:00
              </p>
              <p className="event-details">
                <strong
                  onClick={() => {
                    openPromptMore(5);
                  }}
                >
                  Zumba®️ ZIN ™
                </strong>{" "}
                <strong>
                  {" "}(
                  <strong
                    onClick={() => {
                      openPrompt(1);
                    }}
                  >
                    Johnson Chye
                  </strong>
                  )
                </strong>
              </p>
            </li>
            <li className="block md:flex my-4">
              <p className="event-time md:mr-8 mr-0 md:text-left text-center">
                12:30 - 13:30
              </p>
              <p className="event-details">
                <strong
                  onClick={() => {
                    openPromptMore(3);
                  }}
                >
                  Chakaboom Fitness®️
                </strong>{" "}(
                <strong
                  onClick={() => {
                    openPrompt(3);
                  }}
                >
                  Roberson Magalhaesi
                </strong>)
              </p>
            </li>
            <li className="block md:flex my-4">
              <p className="event-time md:mr-8 mr-0 md:text-left text-center">
                14:00 - 15:00
              </p>
              <p className="event-details">
                <strong
                  onClick={() => {
                    openPromptMore(4);
                  }}
                >
                  Masala Bhangra®️
                </strong>{" "}(
                <strong
                  onClick={() => {
                    openPrompt(5);
                  }}
                >
                  Shah Barian & Aliez Jazam
                </strong>)
              </p>
            </li>
            <li className="block md:flex my-4">
              <p className="event-time md:mr-8 mr-0 md:text-left text-center">
                15:30 - 16:30
              </p>

              <p className="event-details">
                <strong
                  onClick={() => {
                    openPromptMore(2);
                  }}
                >
                  Yonga®️
                </strong>{" "}
                by IFHIAS️{" "}(
                <strong
                  onClick={() => {
                    openPrompt(4);
                  }}
                >
                  Christian Spoerri{" "}
                </strong>)
              </p>
            </li>
            <li className="block md:flex my-4">
              <p className="event-time md:mr-8 mr-0 md:text-left text-center">
                17:00 - 20:00
              </p>
              <p className="event-details">
              LEAP NOW 2022 Reimagined Fitness Concert - William TWS / <strong
                  onClick={() => {
                    openPrompt(3);
                  }}
                >
                  Roberson Magalhaesi
                </strong> /
                    
                  <strong
                    onClick={() => {
                      openPrompt(1);
                    }}
                  >
                    Johnson Chye
                  </strong> / <strong
                  onClick={() => {
                    openPrompt(5);
                  }}
                >Shah & Aliez</strong>
              </p>
            </li>
            <li className="block md:flex mb-12">
              <p className="event-time md:mr-8 mr-0 md:text-left text-center">
                20:30 - 23:00
              </p>
              <p className="event-details">
                After Party (Up-close and personal) - limited spot.
              </p>
            </li>
          </ul>
        </div>
      </div>
      {modalIsOpen && (
        <Modal
          onCancel={closeModalHandler}
          onConfirm={closeModalHandler}
          modalData={prompData}
          onDone={refreshDataHandler}
        />
      )}
      {modalIsOpen && <Backdrop onCancel={closeModalHandler} />}
      {/* <div id="tilt-blue">dsdsdf</div> */}
    </div>
  );
}
