import { useState, useContext } from "react";
import Modal from "../components/Modal";
import Backdrop from "../components/Backdrop";

import "./Presenters.css";
import { MyContext } from "../MyContext";

export default function Presenters() {
  const arrData = useContext(MyContext);
  let [prompData, coachProp] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function closeModalHandler() {
    setModalIsOpen(false);
  }

  function refreshDataHandler(obj) {
    setModalIsOpen(false);
  }
  const openPrompt = (e) => {
    var clickData = arrData.instructors.filter(function (item) {
      return item.id === e;
    });
    const details = {
      type: "instructors",
      value: clickData[0],
    };
    coachProp(details);
    setModalIsOpen(true);
  };
  // setModal(false);

  return (
    <div id="presenters-cont" className="relative container py-11 text-center">
      <div id="confetti-3">
        <img
          src="/assets/images/confetti-3.png"
          alt="decorations"
          className="absolute md:top-4 md:right-24 top-1 right-2 w-24 md:w-auto"
        />
      </div>
      <h3 id="presenters">PRESENTERS</h3>
      <h2 className="md:mt-4 mb-8">Presenter Lineup</h2>
      {/* desktop */}

      <div className="m-auto name-instructor hidden md:block">
        <ul className="md:flex md:flex-wrap">
          <li
            className="cursor-pointer"
            onClick={() => {
              openPrompt(4);
            }}
          >
            <img
              src="/assets/images/coach-christian-spoerri.png"
              alt="instructors"
              className="m-auto presenters-img"
            />
            <p className="my-4 coachesName">Christian Spoerri</p>
          </li>
          <li
            className="mx-2 md:mx-16 cursor-pointer"
            onClick={() => {
              openPrompt(1);
            }}
          >
            <img
              src="/assets/images/coach-johnson-chye.png"
              alt="instructors"
              className="m-auto presenters-img"
            />
            <p className="my-4 coachesName">Johnson Chye</p>
          </li>
          <li
            className="cursor-pointer"
            onClick={() => {
              openPrompt(3);
            }}
          >
            <img
              src="/assets/images/coach-robertson-magalhae.png"
              alt="instructors"
              className="m-auto presenters-img"
            />
            <p className="my-4 coachesName">Robertson Magalhaes</p>
          </li>
        </ul>
      </div>
      <div className="m-auto name-instructor hidden md:block">
        <ul className="md:flex md:flex-wrap my-11">
          <li
            className="cursor-pointer"
            onClick={() => {
              openPrompt(5);
            }}
          >
            <img
              src="/assets/images/coach-shah-aliez.png"
              className="m-auto presenters-img"
              alt="instructors"
            />
            <p className="my-4 coachesName">Shah Barian & Aliez Jazam</p>
          </li>
          <li
            className="mx-2 md:mx-16 cursor-pointer"
            onClick={() => {
              openPrompt(2);
            }}
          >
            <img
              src="/assets/images/coach-william-villaseñor.png"
              alt="instructors"
              className="m-auto presenters-img"
            />
            <p className="my-4 coachesName">William Villaseñor</p>
          </li>
        </ul>
      </div>
      {/* mobile  */}
      <div className="m-auto block md:hidden">
        <ul className="md:flex md:flex-wrap">
          <li
            className="cursor-pointer inline-block"
            onClick={() => {
              openPrompt(4);
            }}
          >
            <img
              src="/assets/images/coach-christian-spoerri.png"
              alt="instructors"
              className="m-auto presenters-img"
            />
            <p className="my-4 coachesName">
              Christian
              <br />
              Spoerri
            </p>
          </li>
          <li
            className="ml-2 md:ml-16 cursor-pointer inline-block"
            onClick={() => {
              openPrompt(1);
            }}
          >
            <img
              src="/assets/images/coach-johnson-chye.png"
              alt="instructors"
              className="m-auto presenters-img"
            />
            <p className="my-4 coachesName">
              Johnson
              <br />
              Chye
            </p>
          </li>
        </ul>
      </div>
      <div className="m-auto block md:hidden">
        <ul className="md:flex md:flex-wrap  my-2">
          <li
            className="cursor-pointer  inline-block"
            onClick={() => {
              openPrompt(3);
            }}
          >
            <img
              src="/assets/images/coach-robertson-magalhae.png"
              alt="instructors"
              className="m-auto presenters-img"
            />
            <p className="my-4 coachesName">
              Robertson
              <br />
              Magalhaes
            </p>
          </li>
          <li
            className="ml-2 md:ml-16 cursor-pointer inline-block"
            onClick={() => {
              openPrompt(5);
            }}
          >
            <img
              src="/assets/images/coach-shah-aliez.png"
              className="m-auto presenters-img"
              alt="instructors"
            />
            <p className="my-4 coachesName">
              Shah Barian
              <br />& Aliez Jazam
            </p>
          </li>
        </ul>
      </div>
      <div className="m-auto name-instructor block md:hidden">
        <ul className="md:flex md:flex-wrap">
          <li
            className="mx-2 md:mx-16 cursor-pointer"
            onClick={() => {
              openPrompt(2);
            }}
          >
            <img
              src="/assets/images/coach-william-villaseñor.png"
              alt="instructors"
              className="m-auto presenters-img"
            />
            <p className="my-4 coachesName">
              William
              <br />
              Villaseñor
            </p>
          </li>
        </ul>
      </div>
      <div id="confetti-4">
        <img
          src="/assets/images/confetti-4.png"
          alt="decorations"
          className="absolute bottom-4 md:left-24 left-14 w-14 md:w-auto"
        />
      </div>
      {modalIsOpen && (
        <Modal
          onCancel={closeModalHandler}
          onConfirm={closeModalHandler}
          modalData={prompData}
          onDone={refreshDataHandler}
        />
      )}
      {modalIsOpen && <Backdrop onCancel={closeModalHandler} />}
    </div>
  );
}
