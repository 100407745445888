import parse from "html-react-parser";

import "./Modal.css";
//import { useState } from "react";
function Modal(props) {
  // let [prompData, coachProp] = useState({});
  // coachProp(promptData)
  function cancelHandler() {
    props.onCancel();
  }
  console.log(props);
  function openVideo(_url) {
    window.open(_url, "_blank");
  }
  const promptData = props.modalData.value;

  return (
    <div
      className={`modal w-full md:w-10/12 md:p-20 py-10 ${
        props.modalData.type === "instructors"
          ? "text-left instuctor-bg"
          : "text-center studio-bg"
      }`}
      id="openModal"
    >
      <div
        className={`md:flex container text-center ${
          props.modalData.type === "instructors"
            ? "md:text-left"
            : "md:text-center"
        }`}
      >
        {props.modalData.type === "instructors" ? (
          <div className="md:w-1/3 relative text-center md:pr-8 my-4 md:my-0 icon m-auto ">
            <img
              src={promptData.img_url}
              alt={promptData.coach_name}
              className="m-auto"
            />
          </div>
        ) : null}
        <div
          className={`overflow-y-auto ${
            props.modalData.type === "instructors" ? "md:w-2/3" : "w-auto"
          }`}
        >
          <div className="h-scrren">
            <h4
              className={`${
                props.modalData.type === "instructors"
                  ? "title-instructor"
                  : "title-studio"
              }`}
            >
              {promptData.title}
            </h4>

            <p className="my-4">{parse(promptData.para1)}</p>
            {promptData.para2 !== "" ? <p>{promptData.para2}</p> : null}
            {promptData.video_url ? (
              <button
                onClick={() => {
                  openVideo(promptData.video_url);
                }}
                className={`btn  mt-10 ${
                  props.modalData.type === "instructors"
                    ? "pink-btn"
                    : "blue-btn"
                }`}
              >
                Watch Video
              </button>
            ) : null}
          </div>
        </div>
      </div>

      <button
        className="absolute btn-close top-8 right-8"
        onClick={cancelHandler}
      >
        <img src="/assets/images/close-btn.png" alt="close" className="img" />
      </button>
    </div>
  );
}

export default Modal;
