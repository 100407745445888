import "./About.css";

export default function About() {
  return (
    <div id="about" className="relative">
      <div className="block md:flex">
        <div className="w-full md:w-1/2 relative">
          <div id="confetti-1">
            <img
              src="/assets/images/confetti-1.png"
              alt="decorations"
              className="absolute md:top-4 md:left--14 lg:w-auto w-14 left-10 "
            />
          </div>
          <div
            id="about-content"
            className="relative md:absolute center-vertical items-center md:pl-0 md:pr-20"
          >
            <h3>ABOUT FITNESS FEST</h3>
            <h2>What is LEAP NOW Fitness Fest 2022</h2>
            <p className="mt-4">
              After a two-and-a-half year hiatus from centre stage in our
              calendars, fitness festivals are making their grand return - and
              they’re back with a bang.  In this year December, LEAP NOW Fitness
              Fest 2022 is reviving the live fitness session with World class
              renowned presenters, introducing new fitness programs such as Hot
              Iron® Cross , a 3 hours hours dancehall with William Villaseñor
              the Wildshaker, and social thrill we’ve been daydreaming of for
              what feels like ages. It’s going to be a Fit-Bash for anybody and
              everybody !
            </p>
          </div>
          <div id="confetti-2" className="hidden md:block">
            <img
              src="/assets/images/confetti-2.png"
              alt="decorations"
              className="absolute bottom-12 right-24 lg:w-auto w-14"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/group-workout.png"
            alt="Banner"
            className="img w-screen h-auto md:w-auto "
          />
        </div>
      </div>
    </div>
  );
}
