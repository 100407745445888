// import { useState, useEffect, createContext } from "react";

import "./App.css";

// components
import Header from "./components/Header";
import Footer from "./components/Footer";
// pages
import About from "./pages/About";
import Partnership from "./pages/Partnership";
import Presenters from "./pages/Presenters";
import Programs from "./pages/Programmes";

function App() {
  return (
    <div className="App">
      <Header />
      <About />

      <Programs />
      <Presenters />

      <Partnership />
      <Footer />
    </div>
  );
}

export default App;
