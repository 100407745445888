import { useEffect } from "react";

import "./Footer.css";

export default function Footer() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <footer id="footer-cont" className="relative">
      <h2 className="mb-8 text-center ">Contact Us</h2>
      <div className="container">
        <div className="md:flex" id="contact-us">
          <div className="w-full md:w-auto">
            <p>
              Office address : SetiaWalk Puchong Unit 6, Level 4, Block K, <br className="lg:block hidden" />
              Pusat Bandar Puchong, 47160 Puchong, Selangor
            </p>
          </div>
          <div className="w-full md:w-auto md:ml-11 mt-4 md:mt-0">
            <p>
              Tel :{" "}
              <a href="tel:60172570796" rel="noreferrer">
                +60 17-257 0796
              </a>
            </p>
            <p>
              Email :{" "}
              <a href="mailto:info@leapnow.com.my" rel="noreferrer">
                info@leapnow.com.my
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="md:flex md:mt-8 m-12">
        <div className="container md:w-1/2 relative">
          <p className="copyright text-center md:text-left">
            © 2022 LEAP NOW Fitness Festival 2022
          </p>
        </div>
        <div className="container w-auto md:w-1/2 ml-11 m-auto relative">
          <ul className="flex m-auto md:m-0 mt-2" id="social-link">
            <li>
              <a
                href="https://www.instagram.com/leapnowfitness/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/instagram.png"
                  alt="instagram"
                  className="w-2/3"
                />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/leapnowfitness" target="_blank" rel="noreferrer">
                <img
                  src="/assets/images/facebook.png"
                  alt="facebook"
                  className="w-2/3"
                />
              </a>
            </li>
          </ul>
          <img
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            src="/assets/images/back-top-top.png"
            alt="Back to top"
            className="fixed right-8 w-14 scroll-top"
          />
        </div>
      </div>
    </footer>
  );
}
