import "./Banner.css";

export default function Banner() {
  const goSlide = (e) => {
    document.getElementById("programmes").scrollIntoView();
  };

  return (
    <div id="banner" className="container pady-48">
      <div className="block md:flex text-center">
        <div className="w-full md:w-1/2 hidden md:block visible">
          <img
            src="/assets/images/banner.png"
            alt="Banner"
            className="img mr-10"
          />
        </div>
        <div className="w-full  md:w-1/2 relative">
          <div
            id="banner-details"
            className="relative md:absolute ml-0 md:ml-10 text-center md:text-left"
          >
            <h1 className="mt-12 md:mt-0">
              WELCOME TO
              <br className="show-desktop" /> LEAP NOW
              <br className="show-desktop" /> Fitness Fest 2022
            </h1>
            <div className="my-4">
              <p>
                Venue :{" "}
                <a
                  href="https://www.kenangacity.com.my/en/starxpo"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  StarXpo Centre
                </a>
              </p>
              <p>10th Dec 2022 | 8am-9pm</p>
            </div>
            <button
              className="btn blue-btn  mb-40 md:mb-0"
              onClick={() => {
                goSlide();
              }}
            >
              VIEW PROGRAMS
            </button>
          </div>
        </div>
        <div className="w-full md:w-1/2 block md:hidden visible">
          <img src="/assets/images/banner.png" alt="Banner" className="img" />
        </div>
      </div>
    </div>
  );
}
