import React, { useState, useEffect } from "react";
// import NavLink from './MobileHeader';
import "./Header.css";
import Banner from "./Banner";
import MobileNav from "./MobileHeader";
export default function Header() {
  const [stickyClass, setStickyClass] = useState("relative");
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 200
        ? setStickyClass(`fixed top-0 left-0 z-50 stickyHeader`)
        : setStickyClass("relative");
    }
  };

  const registerNow = (e) => {
    window.open("https://www.ticket2u.com.my/event/28713", "_blank");
  };
  return (
    <div className="pink-bg">
      <header className="desktop-nav relative">
        <div className={`navigation-bar ${stickyClass}`}>
          <div id="navigation-container">
            <img
              src="/assets/images/logo.png"
              alt="HOmepage"
              className="logo"
            />
            <ul className="nav">
              <li>
                <a href="#about">ABOUT FITNESS FEST</a>
              </li>
              <li>
                <a href="#programmes">PROGRAMMES</a>
              </li>
              <li>
                <a href="#presenters">PRESENTERS</a>
              </li>
              <li>
                <a href="#partnership">PARTNERSHIP</a>
              </li>
            </ul>
            <button
              className="btn absolute green-btn"
              onClick={() => {
                registerNow();
              }}
            >
              REGISTER NOW
            </button>
          </div>
        </div>
      </header>
      <MobileNav />
      <Banner />
    </div>
  );
}
